import React from "react";
import ReactDOM from "react-dom/client";
import AOS from "aos";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "./scss/global.scss";
import "./scss/lib.scss";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

AOS.init();

ReactRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
