import React from "react";
import MarqueeComingSoon, {
  MarqueeDirections
} from "../../components/atoms/MarqueeComingSoon";
import "./ComingSoon.scss";

const ComingSoon = () => {
  return (
    <section id="coming-soon">
      <MarqueeComingSoon direction={MarqueeDirections.LEFT} />
    </section>
  );
};

export default ComingSoon;
