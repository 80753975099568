// Links
export const DISCORD = "https://discord.gg/6vnGjnj2pE";
export const YOUTUBE = "https://www.youtube.com/channel/UC3OGb6HSTtq7mwsL59Dvi6w";
export const STEAM = "https://steamcommunity.com/groups/Play4Buff";
export const TWITTER = "https://twitter.com/Play4Buff";

export const SOCIAL_MEDIA = {
  DISCORD,
  YOUTUBE,
  STEAM,
  TWITTER
};

export const PRIVACY_POLICY = "/pdfs/privacy-policy.pdf";
export const TERMS_OF_USE = "#";

export const HEADER_TRAILER = "https://www.youtube.com/watch?v=jA_iflftl44";
