import { ItemStatus } from "../ui/components/atoms/CardItem";

export const roadmap2023 = [
  {
    status: ItemStatus.DONE,
    text: "Roadmap update"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "E-Sport and gaming partners"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "E-Sport conferences and expositions"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Giveaways"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Official Platform launching"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Adding new games to the platform"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Ability to play quick / pug matches"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Ranks and ranking system"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Achievements / Trophies"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Daily challenges"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Lan tournament qualification"
  },
  {
    status: ItemStatus.INPROGRESS,
    text: "Official Lan Tournament"
  }
];
