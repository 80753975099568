import React from "react";
import "./SocialButton.scss";

export interface ISocialButton {
  href: string;
  iconPath: string;
  altText?: string;
  blank?: boolean;
  className?: string;
}

const SocialButton = ({
  href,
  blank,
  className,
  iconPath,
  altText
}: ISocialButton) => {
  return (
    <a
      href={href}
      target={blank ? "_blank" : "_self"}
      className={`socialButton ${className}`}
      rel="noopener noreferrer"
    >
      <img src={iconPath} alt={altText || "social media icon"} />
    </a>
  );
};

export default SocialButton;
