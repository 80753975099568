import React from "react";
import "./Link.scss";

interface ILink {
  href: string;
  blank?: boolean;
  className?: string;
  children?: string;
}

const Link = ({ href, blank, className, children }: ILink) => {
  return (
    <a
      href={href}
      target={blank ? "_blank" : "_self"}
      className={`link ${className}`}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default Link;
