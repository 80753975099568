import React from "react";
import shortid from "shortid";
import { Row, Col } from "react-bootstrap";
import SocialButton, { ISocialButton } from "../../atoms/SocialButton";
import "./SocialBar.scss";

export enum SocialBarVariants {
  LARGE = "large",
  SMALL = "small"
}

export interface ISocialBar {
  socialMediaList: ISocialButton[];
  variant: SocialBarVariants;
}

const SocialBar = ({ socialMediaList, variant }: ISocialBar) => {
  return (
    <Row className="social-bar">
      <Col xs={10} md={7} className="flex-space-between">
        {socialMediaList.map(
          ({ href, iconPath, altText, blank, className }: ISocialButton) => {
            return (
              <SocialButton
                key={shortid()}
                href={href}
                iconPath={iconPath}
                altText={altText}
                blank={blank}
                className={`${className} social-bar-${variant}`}
              />
            );
          }
        )}
      </Col>
    </Row>
  );
};

export default SocialBar;
