import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PRIVACY_POLICY } from "../../../constants";
import { socialMediaListFooter } from "../../../data/socialMediaList";
import SocialBar, { SocialBarVariants } from "../../components/molecules/SocialBar";
import Link from "../../components/atoms/Link";
import Logo, { Extensions } from "../../components/atoms/Logo";
import "./Footer.scss";

const Footer = () => {
  return (
    <section id="footer">
      <Container fluid="xl">
        <div className="footer-logo">
          <Logo
            fileName="logo-small"
            extension={Extensions.SVG}
            altText="Play4buff logo"
            href="#root"
            isLink
          />
        </div>

        <Row className="footer-content">
          <Col
            xs={{ span: 12, offset: 0, order: 2 }}
            md={{ span: 3, offset: 1, order: 1 }}
            lg={{ span: 3, offset: 0, order: 1 }}
            className="links"
          >
            <Link href={PRIVACY_POLICY} blank>
              PRIVACY POLICY
            </Link>
            {/* <Link href={TERMS_OF_USE} blank>
              TERMS OF USE
            </Link> */}
          </Col>
          <Col
            xs={{ span: 8, offset: 0, order: 1 }}
            sm={{ span: 6, offset: 0, order: 1 }}
            md={{ span: 3, offset: 0, order: 2 }}
            lg={{ span: 4, offset: 1, order: 2 }}
            className="social-icons"
          >
            <SocialBar socialMediaList={socialMediaListFooter} variant={SocialBarVariants.SMALL} />
          </Col>
          <Col
            xs={{ span: 12, offset: 0, order: 3 }}
            md={{ span: 4, offset: 0, order: 3 }}
            lg={{ span: 3, offset: 1, order: 3 }}
          >
            <p className="policy">PLAY4BUFF 2022. All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
