import React from "react";

export enum Extensions {
  PNG = "png",
  JPG = "jpg",
  SVG = "svg"
}

interface ILogo {
  fileName: string;
  extension: Extensions;
  altText?: string;
  isLink?: boolean;
  href?: string;
  className?: string;
}

const Logo = ({
  fileName,
  extension,
  altText,
  isLink = false,
  href,
  className
}: ILogo) => {
  const img = (
    <>
      <img
        src={`images/${extension}/${fileName}.${extension}`}
        alt={altText || "logo"}
        className={`desktop-block ${className}`}
      />
      <img
        src={`images/${extension}/${fileName}-mobile.${extension}`}
        alt={altText || "logo"}
        className={`mobile-block ${className}`}
      />
    </>
  );

  if (isLink) return <a href={href}>{img}</a>;

  return img;
};

export default Logo;
