import React from "react";
import shortid from "shortid";
import CardItem, { ICardItem } from "../../atoms/CardItem";
import "./RoadmapCard.scss";

interface IRoadmapCard {
  title: string;
  itemList: ICardItem[];
}

const RoadmapCard = ({ title, itemList }: IRoadmapCard) => {
  return (
    <div
      className="roadmapCard"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-duration="600"
      data-aos-easing="ease-out"
    >
      <h3>{title}</h3>
      <hr />
      {itemList.map(({ status, text }: ICardItem) => {
        return <CardItem key={shortid()} status={status} text={text} />;
      })}
    </div>
  );
};

export default RoadmapCard;
