import { SOCIAL_MEDIA } from "../constants";

export const socialMediaList = [
  {
    href: SOCIAL_MEDIA.DISCORD,
    iconPath: "images/svg/icon-discord.svg",
    altText: "discord icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.YOUTUBE,
    iconPath: "images/svg/icon-youtube.svg",
    altText: "youtube icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.STEAM,
    iconPath: "images/svg/icon-steam.svg",
    altText: "steam icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.TWITTER,
    iconPath: "images/svg/icon-twitter.svg",
    altText: "twitter icon",
    blank: true
  }
];

export const socialMediaListFooter = [
  {
    href: SOCIAL_MEDIA.DISCORD,
    iconPath: "images/svg/icon-black-discord.svg",
    altText: "discord icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.YOUTUBE,
    iconPath: "images/svg/icon-black-youtube.svg",
    altText: "youtube icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.STEAM,
    iconPath: "images/svg/icon-black-steam.svg",
    altText: "steam icon",
    blank: true
  },
  {
    href: SOCIAL_MEDIA.TWITTER,
    iconPath: "images/svg/icon-black-twitter.svg",
    altText: "twitter icon",
    blank: true
  }
];
