import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SocialBar, { SocialBarVariants } from "../../components/molecules/SocialBar";
import "./SocialMedia.scss";
import { socialMediaList } from "../../../data/socialMediaList";

const SocialMedia = () => {
  return (
    <section id="social-media">
      <Container fluid="xl">
        <Row className="content">
          <Col xs={8} sm={5} md={4} lg={4}>
            <SocialBar socialMediaList={socialMediaList} variant={SocialBarVariants.LARGE} />
          </Col>
          <Col>
            <h2 className="uppercase text-center">Stay tuned</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SocialMedia;
