import React, { memo } from "react";
import "./Button.scss";

export enum ButtonVariants {
  CONTAINED = "contained",
  OUTILINED = "outlined"
}

interface IButton {
  id?: string;
  variant: ButtonVariants;
  onClick?: () => void;
  children: string;
  className?: string;
  isLink?: boolean;
  href?: string;
  blank?: boolean;
}

const Button = ({
  id,
  variant,
  onClick,
  children,
  className,
  isLink = false,
  href,
  blank
}: IButton) => {
  const iconPath =
    variant === ButtonVariants.CONTAINED
      ? "images/svg/btn-arrow-dark.svg"
      : "images/svg/btn-arrow-white.svg";

  const Button: React.FC = () => {
    return (
      <button
        id={id}
        className={`button button-${variant} ${className}`}
        onClick={onClick}
      >
        <span>{children}</span>
        <img src={iconPath} alt="button icon" className="button-icon" />
      </button>
    );
  };

  const withLink = (href: string) => {
    return (
      <a
        href={href}
        target={blank ? "_blank" : "_self"}
        rel="noreferrer"
        className="button-link"
      >
        <Button />
      </a>
    );
  };

  if (isLink && href) {
    return <>{withLink(href)}</>;
  }

  return <Button />;
};

export default memo(Button);
