import React from "react";
import { Container } from "react-bootstrap";
import RoadmapCard from "../../components/molecules/RoadmapCard";
import { roadmap2023 } from "../../../data/roadmapPlan";
import "./Roadmap.scss";

const Roadmap = () => {
  return (
    <section id="roadmap">
      <div className="roadmap-margin desktop-block" />

      <Container fluid="lg">
        <div className="roadmap-header">
          <img src="images/svg/roadmap-header-line.svg" alt="Roadmap" className="desktop-block" />
          <img src="images/svg/roadmap-header-line-mobile.svg" alt="Roadmap" className="mobile-block" />

          <div
            className="heading-box"
            data-aos="zoom-in-up"
            data-aos-offset="100"
            data-aos-delay="50"
            data-aos-easing="ease-out"
            data-aos-duration="800"
          >
            <h1 className="uppercase">Roadmap</h1>
          </div>
          <div className="header-blur"></div>
        </div>

        <RoadmapCard title="2023" itemList={roadmap2023} />
      </Container>

      <div className="roadmap-margin desktop-block" />
    </section>
  );
};

export default Roadmap;
