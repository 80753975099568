import React from "react";
import Header from "../../sections/Header";
import ComingSoon from "../../sections/ComingSoon";
import Roadmap from "../../sections/Roadmap";
import SocialMedia from "../../sections/SocialMedia";
import Footer from "../../sections/Footer";
import "./LandingPage.scss";

const LandingPage = () => {
  return (
    <div id="landing-page">
      <Header />
      <ComingSoon />
      <Roadmap />
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default LandingPage;
