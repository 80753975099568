import React from "react";
import Loader from "../Loader";
import "./CardItem.scss";

export enum ItemStatus {
  INPROGRESS = "inprogress",
  DONE = "done"
}

interface IVariantItem {
  text: string;
}

export interface ICardItem {
  status: ItemStatus;
  text: string;
}

const InprogressItem = ({ text }: IVariantItem) => (
  <div
    className="cardItem"
    data-aos="fade-up"
    data-aos-offset="100"
    data-aos-delay="100"
    data-aos-easing="linear"
    data-aos-duration="600"
  >
    <Loader />
    <p className="cardItem-text">{text}</p>
  </div>
);

const DoneItem = ({ text }: IVariantItem) => (
  <div
    className="cardItem"
    data-aos="fade-up"
    data-aos-offset="100"
    data-aos-delay="100"
    data-aos-easing="linear"
    data-aos-duration="600"
  >
    <img src="images/svg/icon-checked.svg" alt="in progress status icon" />
    <p className="cardItem-text">{text}</p>
  </div>
);

const CardItem = ({ status, text }: ICardItem) => {
  switch (status) {
    case ItemStatus.INPROGRESS:
      return <InprogressItem text={text} />;
    case ItemStatus.DONE:
      return <DoneItem text={text} />;
    default:
      return null;
  }
};

export default CardItem;
