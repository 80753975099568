import React from "react";
import { Row, Col } from "react-bootstrap";
import { HEADER_TRAILER } from "../../../constants";
import Button, { ButtonVariants } from "../../components/atoms/Button";
import Logo, { Extensions } from "../../components/atoms/Logo";
import "./Header.scss";

const Header = () => {
  return (
    <section id="header">
      <video src="video/header-video.mp4" muted loop autoPlay className="video" />
      <div className="video-overlay" />
      <Row className="header-contnet">
        <Col xs="auto" className="flex-column-center">
          <Logo fileName="logo" extension={Extensions.SVG} altText="Play4buff logo" className="logo" />
          <p className="logo-text">play 2 earn gaming platform</p>

          <Button
            variant={ButtonVariants.CONTAINED}
            className="header-button"
            isLink
            blank
            href={HEADER_TRAILER}
          >
            watch trailer
          </Button>
          <Button variant={ButtonVariants.OUTILINED} className="header-button" isLink href="#roadmap">
            roadmap
          </Button>
        </Col>
      </Row>
    </section>
  );
};

export default Header;
