import React from "react";
import "./MarqueeComingSoon.scss";

export enum MarqueeDirections {
  LEFT = "left",
  RIGHT = "right"
}

interface IMarqueeComingSoon {
  direction: MarqueeDirections;
}

const MarqueeComingSoon = ({ direction }: IMarqueeComingSoon) => {
  return (
    <div className="marquee">
      <div className={`marquee-content direction-${direction}`}>
        <h4 className="marquee-item">Platform coming soon...</h4>
        <h4 className="marquee-item marquee-item-black">
          Platform coming soon....
        </h4>
        <h4 className="marquee-item">Platform coming soon....</h4>
        <h4 className="marquee-item marquee-item-black">
          Platform coming soon....
        </h4>
      </div>

      <div className={`marquee-content direction-${direction}`}>
        <h4 className="marquee-item">Platform coming soon...</h4>
        <h4 className="marquee-item marquee-item-black">
          Platform coming soon....
        </h4>
        <h4 className="marquee-item">Platform coming soon....</h4>
        <h4 className="marquee-item marquee-item-black">
          Platform coming soon....
        </h4>
      </div>
    </div>
  );
};

export default MarqueeComingSoon;
