import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader">
      <img src="images/svg/icon-loader.svg" alt="loader" />
    </div>
  );
};

export default Loader;
